.container {
  max-width: 1180px;
  &.container-fluid {
    margin: 0 auto;

    &.content-wrapper {
      padding: 0;
    }
  }
}
